import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import Logo from '../../images/rentlio-logo.svg'
import LogoWhite from '../../images/rentlio-logo-white.svg'

const LogoWrapper = ({ mode, isHeadroom, locale, open, location }) => {
  const path = locale === 'hr' ? '/' : `/${locale}`

  return (
    <Link to={`${path}${location.search}`}>
      {isHeadroom ? (
        <img src={Logo} alt="Rentlio Logo" />
      ) : open ? (
        <img src={Logo} alt="Rentlio Logo" />
      ) : (
        <img src={mode === 'dark' ? Logo : LogoWhite} alt="Rentlio Logo" />
      )}
    </Link>
  )
}

LogoWrapper.defaultProps = {
  location: {
    search: ''
  }
}

LogoWrapper.propTypes = {
  mode: PropTypes.string,
  isHeadroom: PropTypes.bool,
  location: PropTypes.object
}

export default LogoWrapper
